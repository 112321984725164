<template>
  <footer class="d-flex flex-column pa-0 base-footer">
    <div class="d-flex flex-column py-9 px-16">
      <h2 class="text-center px-7 py-12">Les partenaires de ProtecSolame</h2>
      <v-container fluid="true" class="w-75">
        <v-row align="center">
          <v-col cols="12" sm="2"></v-col>
          <v-col cols="12" sm="2" class="text-center">
            <img height="60" :src="require('@/assets/rep-fr.jpg')" alt="" />
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <img height="80" :src="require('@/assets/reg_reu.png')" alt="" />
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <img height="60" :src="require('@/assets/Logo-UE.jpg')" alt="" />
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <img height="70" :src="require('@/assets/edf.png')" alt="" />
          </v-col>
          <v-col cols="12" sm="2"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2"></v-col>
          <v-col cols="12" sm="2" class="mt-4 text-center">
            <img height="40" :src="require('@/assets/cirbat.jpg')" alt="" />
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <img height="80" :src="require('@/assets/imageen.jpg')" alt="" />
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <img height="60" :src="require('@/assets/piment.jpeg')" alt="" />
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <img height="60" :src="require('@/assets/univ_reu.jpg')" alt="" />
          </v-col>
          <v-col cols="12" sm="2"></v-col>
        </v-row>
      </v-container>
    </div>
    <div
      class="bg-primary-darken-1 pa-9 ga-3 d-flex flex-column w-100 align-center justify-center px-4"
    >
      <img :src="require('@/assets/protecsolame.png')" class="h24" alt="" />
      <p>URMA EST- Rue Comorapoullé 97440 Saint André – 02 62 58 87 90</p>
      <p>Copyright © 2024 CIRBAT – Tous Droits Réservés</p>
      <div>
        <a
          class="text-decoration-none text-black"
          href="https://protecsolame.fr/mentions-legales/"
        >
          Mentions légales
        </a>
        <span class="mx-2">|</span>
        <a
          class="text-decoration-none text-black"
          href="https://protecsolame.fr/politique-confidentialite/"
        >
          Politique de confidentialité
        </a>
      </div>
    </div>

    <div class="px-4 py-2 bg-secondary text-center w-100">
      Pour toute demande de renseignement complémentaire, veuillez contacter:
      <a class="text-white" href="mailto:contact@protecsolame.fr"
        >contact@protecsolame.fr</a
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BaseFooter',
}
</script>

<style scoped>
.base-footer {
  position: absolute;
  width: 100%;
}
.v-row {
  display: flex;
  flex-wrap: wrap !important;
  justify-content: center;
  align-items: center;
}
.v-col-3 {
  flex: 0 0 25%;
  max-width: 100% !important;
}
.h24 {
  height: 60px;
}

@media all and (max-width: 600px) {
  .h24 {
    height: 32px;
  }
}
@media (max-width: 768px) {
  .base-footer .v-container {
    width: 100%; /* Assure que le conteneur prend toute la largeur */
  }
  .base-footer {
    padding-top: 20px;
    padding-right: 19px;
    padding-bottom: 30px;
    padding-left: 19px;
  }
  .img {
    margin-left: -50px;
  }
  /* .v-container {
    width: 100%;
    padding: 4px !important;
    margin-right: 60px;
    margin-left: auto;
  } */

  .base-footer .v-row {
    flex-direction: column; /* Aligne les éléments verticalement */
  }

  .base-footer .v-col {
    width: 100%; /* Les colonnes prennent toute la largeur */
    text-align: center; /* Centrer le contenu */
  }

  .base-footer img {
    margin-bottom: 10px;
  }

  .base-footer .px-4,
  .base-footer .py-2 {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .base-footer .text-center {
    text-align: center;
  }
}
</style>
