<template>
  <div>
    <base-header />
    <div class="base-main">
      <calculate-form />
    </div>
    <base-footer />
  </div>
</template>

<script>
import CalculateForm from '@/components/calculate/CalculateForm'
import BaseHeader from '@/components/BaseHeader'
import BaseFooter from '@/components/BaseFooter'
export default {
  components: { BaseFooter, BaseHeader, CalculateForm },
}
</script>
<style scoped lang="sass">
.base-main
  //min-height: 473px
</style>
