/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import router from "@/plugins/router";
import store from "@/plugins/store";
import Logger from "@/plugins/logger";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./style.css";
/* eslint-disable */
const app = createApp(App);
app.use(vuetify);
app.use(router);
app.use(store);
app.use(Logger, store);

app.mount("#app");
