<template>
  <div>
    <div>
      <ul class="levels">
        <li v-for="(level, index) in levels" :key="index" class="level">
          <div class="level-indicator">
            <span
              v-if="
                currentLevel === level.label || currentLevel === level.value
              "
            >
              <v-icon icon="mdi-arrow-right-bold-outline" />
            </span>
          </div>
          <div class="level-bar">
            <span class="level-bar-label pa-4" :style="{ color: level.color }">
              {{ level.label }}
            </span>
            <div
              class="level-bar-inner"
              :style="{
                width: level.width,
                backgroundColor: level.background,
              }"
            ></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

const defaultLevels = [
  {
    label: 'Bon',
    color: '#fff',
    background: '#00a653',
    width: '100%',
    value: 7,
  },
  {
    label: '',
    color: '#fff',
    background: '#4fb848',
    width: '90%',
    value: 6,
  },
  {
    label: '',
    color: '#fff',
    background: '#c0d731',
    width: '80%',
    value: 5,
  },
  {
    label: 'Moyen',
    color: '#050505',
    background: '#fef204',
    width: '70%',
    value: 4,
  },
  {
    label: '',
    color: '#fff',
    background: '#fbb814',
    width: '60%',
    value: 3,
  },
  {
    label: '',
    color: '#fff',
    background: '#f57020',
    width: '50%',
    value: 2,
  },
  {
    label: 'Mauvais',
    color: '#fff',
    background: '#ff0100',
    width: '40%',
    value: 1,
  },
]

export default defineComponent({
  name: 'LevelComponent',
  props: {
    currentLevel: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    levels() {
      return defaultLevels
    },
  },
})
</script>

<style lang="sass" scoped>
.level-indicator
  margin-right: 16px
  display: flex
  align-items: center
  width: 16px

.levels
  list-style: none
  margin: 0
  padding: 0

.level
  display: flex
  align-items: center
  margin-bottom: 8px

.level-bar-label
  position: relative
  z-index: 1
  color: #fff
  font-size: 12px
  font-weight: bold

.level-bar
  height: 20px
  position: relative
  width: 100%

.level-bar-inner
  height: 100%
  background-color: #4caf50
  position: absolute
  top: 0
  left: 0
</style>
