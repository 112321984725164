<template>
  <v-row>
    <v-col cols="6">
      <div class="infobulle-container">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <div class="infobulle">
          <img
            :src="require('@/assets/brise_soleil.png')"
            alt="Description de l'image"
            height="250"
          />
        </div>
      </div>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-if="!isMobile"
            label="Largeur (cm)"
            type="number"
            v-model="form.width"
            min="5"
            max="25"
            step="5"
            :onclick="calculAll"
          ></v-text-field>
          <v-select
            v-else
            label="Largeur (cm)"
            :items="widthOptions"
            v-model="form.width"
            @update:modelValue="calculAll"
            :menu-props="{ maxHeight: '200px' }"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="!isMobile"
            label="Espacement (cm)"
            type="number"
            v-model="form.spacing"
            min="5"
            max="40"
            step="5"
            :onclick="calculAll"
          >
          </v-text-field>
          <v-select
            v-else
            label="Espacement (cm)"
            :items="EspacementOptions"
            v-model="form.spacing"
            :menu-props="{ maxHeight: '200px' }"
            @update:modelValue="calculAll"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-if="!isMobile"
            label="Inclinaison (°)"
            type="number"
            v-model="form.tilt"
            min="0"
            max="50"
            step="10"
            :onclick="calculAll"
          >
          </v-text-field>
          <v-select
            v-else
            :onchange="calculAll"
            label="Inclinaison (°)"
            :items="InclinaisonOptions"
            v-model="form.tilt"
            :menu-props="{ maxHeight: '200px' }"
            @update:modelValue="calculAll"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="!isMobile"
            label="Surface occultée (%)"
            type="number"
            v-model="form.occultation"
            min="25"
            max="100"
            step="25"
            :onclick="calculAll"
          >
          </v-text-field>
          <v-select
            v-else
            :onchange="calculAll"
            label="Surface occultée (%)"
            :items="occultationOptions"
            v-model="form.occultation"
            :menu-props="{ maxHeight: '200px' }"
            @update:modelValue="calculAll"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <div id="app" class="scene2d">
        <ThreeScene
          :espacement="form.spacing"
          :inclinaison="form.tilt"
          :largeur="form.width"
          :occule="form.occultation"
        /></div
    ></v-col>
  </v-row>
  <!-- new view -->
  <div class="container mt-5">
    <div class="row">
      <div :class="result == 'VALIDE' ? 'col-12 text-center' : 'col-7'">
        <div class="d-flex align-items-center">
          <h3 class="mb-0 me-2">Coefficient Cm :</h3>
          <div class="d-inline-block p-2 bg-light d-flex align-items-center">
            <span
              class="h2"
              :class="result == 'VALIDE' ? 'text-success' : 'text-danger'"
              >{{ coefficient_result[0] }}</span
            >
            <!-- <span
              class="badge ms-3"
              :class="result == 'VALIDE' ? 'bg-success' : 'bg-danger'"
              >{{ result }}</span
            > -->
          </div>
        </div>
      </div>
      <div class="col-5">
        <!-- <h5>Informations :</h5>
        <div class="text-danger">{{ info_error_result }}</div> -->
      </div>
    </div>

    <div class="row mt-4" v-if="room == 2">
      <div class="col-12 text-start">
        <div class="d-flex align-items-center">
          <strong>Autonomie en lumière naturelle :</strong>
          <div class="d-inline-block p-2 d-flex align-items-center">
            <span class="h2 text-success"
              >{{ (autonomie[0] * 100).toFixed(0) }} %</span
            >
            <span>du temps avec lumière naturelle (en ciel dégagé)</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-start">
        <div class="d-flex align-items-center">
          <strong>Consommation annuelle associée :</strong>
          <div class="d-inline-block p-2 d-flex align-items-center">
            <span class="h2 text-success"
              >{{ (consommation[0] * 100).toFixed(0) }} %</span
            >
            consommation électrique annuelle évitée pour une pièce climatisée à
            25°C
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 text-center">
        <h4 class="text-danger">
          <i class="fa fa-arrow-repeat"></i> Qualité du brise soleil :
        </h4>
      </div>
    </div>

    <div class="row">
      <div class="col-6 text-center">
        <p class="text-danger">Niveau d'éclairement :</p>
      </div>
      <div class="col-6 text-center">
        <p class="text-danger">Niveau de protection (selon Cm) :</p>
      </div>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="6">
          <div class="d-flex align-items-center">
            <!-- Flèche dynamique -->
            <div
              class="arrow-container me-3"
              :style="{ top: nivEclairementclairement + 'px' }"
            >
              <i class="fa fa-arrow-right text-dark"></i>
            </div>
            <!-- Échelle de qualité -->
            <div class="quality-meter">
              <div class="quality-meter-item bg-success">Bon</div>
              <div class="quality-meter-item bg-success-light-two"></div>
              <div class="quality-meter-item bg-success-light"></div>
              <div class="quality-meter-item bg-yellow">Moyen</div>
              <div class="quality-meter-item bg-orange"></div>
              <div class="quality-meter-item bg-orange-two"></div>
              <div class="quality-meter-item bg-danger">Mauvais</div>
            </div>
            <div
              class="arrow-container me-3"
              :style="{ top: nivProtection + 'px' }"
            >
              <i class="fa fa-arrow-left text-dark"></i>
            </div>
          </div>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ThreeScene from '../scene2d.vue'
import Papa from 'papaparse'
export default {
  name: 'SunshadeDefinitionStep',
  components: {
    ThreeScene,
  },
  props: {
    modelValue: Object,
    location: Int8Array,
    orientation: Int8Array,
    critere: Int8Array,
    room: Int8Array,
  },
  data() {
    return {
      fullscreen: false,
      formWidth: {
        width: 10, // Valeur par défaut
      },
      widthOptions: [5, 10, 15, 20, 25],
      EspacementOptions: [5, 10, 15, 20, 25, 30, 35, 40],
      InclinaisonOptions: [0, 10, 20, 30, 40, 50],
      occultationOptions: [25, 50, 75, 100],
      coefficient_result: [0],
      result: 'Géométriquement impossible',
      autonomie: [0],
      consommation: [0],
      info_error_result: ' ',
      info_error: [
        "Surface occultée sur-protégée :Risque d'obscurité dans la pièce,Solution :1) Réduire la largeur des lames ; 2) Augmenter l'espacement entre les lames ; 3) Réduire l'inclinaison des lames",
        "Surface occultée trop exposée : risque d'eblouissement Solutions : 1) Augmenter la largeur des  lames ; 2) Réduire l'espacement entre les lames ; 3) Augmenter l'inclinaison des lames",
      ],
      nivEclairement: 100,
      nivProtection: 100,
      positionArray: [100, 65, 30, -1, -40, -75, -105],
      borne_acceptabilite: [0.15, 0.7],
      code_unique: '',
      distance_vitrage: '50',
      csvData: [],
      currentBatch: 1,
    }
  },
  computed: {
    form() {
      return this.modelValue
    },
    isMobile() {
      return window.innerWidth <= 768
    },
  },
  methods: {
    loadCSV() {
      fetch('/Tableur_data.csv')
        .then((response) => response.text())
        .then((csvText) => {
          Papa.parse(csvText, {
            complete: (results) => {
              this.csvData = results.data
              this.calculAll()
            },
            header: false,
            skipEmptyLines: true,
          })
        })
        .catch((error) => {
          console.error('Erreur lors du chargement du fichier CSV :', error)
        })
    },
    getValue(columnDepart, columnFin) {
      // Recherchez les lignes où la colonne 21 correspond à this.codeUnique
      const filteredRows = this.csvData.filter(
        (row) => row[21] === this.code_unique
      )

      // Obtenez la valeur de la colonne spécifique en fonction du critère
      var valeur = filteredRows.map((row) => {
        if (this.critere === 1) {
          return parseFloat(row[columnDepart]) // Si critère == 1, récupérez la valeur de la colonne 22
        } else {
          return parseFloat(row[columnFin]) // Sinon, récupérez la valeur de la colonne 23
        }
      })
      return valeur
    },
    disableTyping(event) {
      event.preventDefault()
    },
    updateeclairement(position) {
      // Met à jour la position de la flèche, par exemple : 0 pour "Bon", 50 pour "Moyen", 100 pour "Très Mauvais"
      this.eclairement = position
    },
    calculAll() {
      console.log('change')
      this.code_unique =
        this.location +
        this.form.occultation +
        this.form.tilt +
        this.orientation +
        this.form.width +
        this.form.spacing +
        this.distance_vitrage
      console.log('code unique')
      console.log(this.code_unique)
      this.coefficient_result = this.getValue(22, 23)
      this.autonomie = this.getValue(25, 26)
      this.consommation = this.getValue(27, 27)
      // niveau eclairement, autonomie
      if (this.autonomie < 0.14) {
        this.nivEclairement = this.positionArray[0]
      } else if (this.autonomie >= 0.14 && this.autonomie < 0.29) {
        this.nivEclairement = this.positionArray[1]
      } else if (this.autonomie >= 0.29 && this.autonomie < 0.43) {
        this.nivEclairement = this.positionArray[2]
      } else if (this.autonomie >= 0.43 && this.autonomie < 0.57) {
        this.nivEclairement = this.positionArray[3]
      } else if (this.autonomie >= 0.57 && this.autonomie < 0.71) {
        this.nivEclairement = this.positionArray[4]
      } else if (this.autonomie >= 0.71 && this.autonomie < 0.85) {
        this.nivEclairement = this.positionArray[5]
      } else {
        this.nivEclairement = this.positionArray[6]
      }
      // niveau protection, coefficient cm
      if (this.coefficient_result > 0.84) {
        this.nivProtection = this.positionArray[0]
      } else if (this.coefficient_result > 0.7) {
        this.nivProtection = this.positionArray[1]
      } else if (this.coefficient_result > 0.56) {
        this.nivProtection = this.positionArray[2]
      } else if (this.coefficient_result > 0.42) {
        this.nivProtection = this.positionArray[3]
      } else if (this.coefficient_result > 0.28) {
        this.nivProtection = this.positionArray[4]
      } else if (this.coefficient_result > 0.14) {
        this.nivProtection = this.positionArray[5]
      } else {
        this.nivProtection = this.positionArray[6]
      }
      if (this.coefficient_result != 0) {
        if (
          this.coefficient_result > this.borne_acceptabilite[0] &&
          this.coefficient_result < this.borne_acceptabilite[1]
        ) {
          this.result = 'VALIDE'
        } else if (this.coefficient_result < this.borne_acceptabilite[0]) {
          this.result = 'NON OPTIMISE'
          this.info_error_result = this.info_error[0]
        } else if (this.coefficient_result > this.borne_acceptabilite[1]) {
          this.result = 'NON OPTIMISE'
          this.info_error_result = this.info_error[1]
        }
      } else {
        this.result = 'Géométriquement impossible'
      }
    },
  },
  mounted() {
    this.loadCSV()
  },
}
</script>

<style scoped>
@media all and (max-width: 500px) {
  .v-row {
    flex-direction: column !important;
  }
  .v-col-6 {
    flex: 0 0 50%;
    max-width: 100% !important;
  }
  .scene2d {
    margin-left: -65px; /* Déplace légèrement vers la gauche */
    text-align: left; /* Aligne le texte à gauche pour les petits écrans */
  }
}
@media all and (max-width: 500px) {
  .v-col img {
    width: 300px !important;
    margin-top: 40px !important;
  }
  .v-container {
    width: 100%;
    padding: 4px !important;
    margin-right: auto;
    margin-left: auto;
  }
}
.scene2d {
  text-align: center;
}
.bg-light {
  background-color: #f8f9fa;
}

.text-success {
  color: #28a745;
}

.text-danger {
  color: #dc3545;
}

.quality-meter {
  display: inline-block;
  text-align: left;
  width: 120px;
}

.quality-meter-item {
  width: 100%;
  padding: 5px;
  color: white;
  margin-bottom: 2px;
}

.bg-success {
  background-color: #28a745;
}

.bg-lime {
  background-color: #cddc39; /* Lime color */
}

.bg-warning {
  background-color: #ffc107;
}

.indicator {
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
}

.position-relative {
  position: relative;
}

.translate-middle {
  transform: translate(-50%, -50%);
  top: 50%;
  right: -20px;
  font-size: 1.5rem;
}
.quality-meter {
  width: 150px;
}

.quality-meter-item {
  color: white;
  padding: 5px;
  margin-bottom: 5px;
  height: 30px;
}

.arrow-container {
  position: relative;
  height: 100%;
}
.bg-success-light-two {
  background-color: #59d777;
}

.bg-success-light {
  background-color: #c6e48b;
}

.bg-orange {
  background-color: #ffa500;
}
.bg-orange-two {
  background-color: #d79c2e;
}

.bg-danger {
  background-color: #ff4500;
}
.small-container {
  width: 300px;
  height: 300px;
  background-color: #f0f0f0;
  position: relative;
}

.fullscreen-container {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}
.infobulle-container {
  position: relative;
  display: inline-block;
}

.image {
  width: 100px;
  height: 100px;
}

.infobulle {
  visibility: hidden;
  width: 470px;
  background-color: #fff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -100px; /* Positionne l'infobulle au-dessus de l'image */
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
  height: 320px;
}

.infobulle::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.infobulle-container:hover .infobulle {
  visibility: visible;
  opacity: 1;
}
</style>
