<template>
  <v-layout>
    <v-main style="min-height: 300px">
      <base-logger />
      <router-view></router-view>
    </v-main>
  </v-layout>
</template>

<script>
import BaseLogger from '@/components/BaseLogger'
export default {
  name: 'App',
  components: { BaseLogger },
}
</script>

<style></style>
