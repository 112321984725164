<template>
  <v-container class="py-2 base-header">
    <v-row justify="space-between">
      <v-col class="text-center">
        <a href="https://protecsolame.fr//">
          <img
            :src="require('@/assets/base-header-1.png')"
            class="height"
            alt=""
          />
        </a>
      </v-col>
      <v-col class="text-center">
        <a href="https://protecsolame.fr//">
          <img :src="require('@/assets/header-2.png')" class="height" alt="" />
        </a>
      </v-col>
      <v-col class="text-center">
        <a href="https://protecsolame.fr//">
          <img
            :src="require('@/assets/base-header-3.png')"
            class="height"
            alt=""
          />
        </a>
      </v-col>
    </v-row>
    <nav class="et-menu-nav">
      <div v-if="isMobile" class="menu-toggle" @click="toggleMenu">
        <span v-if="!isMenuOpen">&#9776;</span>
        <span v-if="isMenuOpen">&#10005;</span>
      </div>
      <ul id="menu-principal" class="et-menu nav" :class="{ open: isMenuOpen }">
        <li class="menu-item menu-item-has-children">
          <a href="#" class="button-link">
            La protection solaire des fenêtres et baies
            <span v-if="!isMobile" class="dropdown-icon">&#9662;</span>
          </a>
          <ul class="sub-menu">
            <li class="menu-item">
              <a href="https://protecsolame.fr/pourquoi-proteger-baies/">
                Pourquoi protéger ses baies
              </a>
            </li>
            <li class="menu-item">
              <a href="https://protecsolame.fr/aides-financieres/">
                Les aides financières
              </a>
            </li>
            <li class="menu-item">
              <a
                href="https://protecsolame.fr/confort-thermique-confort-visuel/"
              >
                Confort Thermique et Confort Visuel
              </a>
            </li>
            <li class="menu-item">
              <a href="https://protecsolame.fr/reglementation/">
                La réglementation
              </a>
            </li>
          </ul>
        </li>
        <li class="menu-item menu-item-has-children">
          <a href="#" class="button-link"
            >Solutions Techniques
            <span v-if="!isMobile" class="dropdown-icon">&#9662;</span>
          </a>
          <ul class="sub-menu">
            <li class="menu-item">
              <a href="https://protecsolame.fr/casquettes-joues/">
                Casquettes et Joues
              </a>
            </li>
            <li class="menu-item">
              <a href="https://protecsolame.fr/lames-brise-soleils/">
                Lames brise-soleils
              </a>
            </li>
          </ul>
        </li>
        <li class="menu-item">
          <a href="https://app.protecsolame.fr/">Outil de calcul</a>
        </li>
        <li class="menu-item">
          <a href="https://protecsolame.fr/telechargement/">Téléchargement</a>
        </li>
      </ul>
    </nav>
  </v-container>
</template>

<script>
export default {
  name: 'BaseHeader',
  data() {
    return {
      isMenuOpen: false,
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768
    },
  },
}
</script>

<style scoped>
.base-header {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.justify-space-between {
  justify-content: space-between !important;
  align-items: center !important;
}

.height {
  height: 60px;
}

.v-card {
  margin-top: 10px;
}
/* style nav */
.et-menu-nav {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center; /* Centre le contenu horizontalement */
}

.et-menu.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row; /* Aligne les éléments horizontalement */
}

.menu-item {
  margin-right: 20px; /* Espace entre les éléments de menu */
  position: relative; /* Pour positionner les sous-menus */
}

.menu-item:last-child {
  margin-right: 0; /* Supprime l'espace après le dernier élément */
}

.menu-item > a {
  text-decoration: none;
  font-family: 'Manrope', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #3e5a73 !important;
}

.sub-menu {
  list-style-type: none;
  border-top: 3px solid #bed630;
  padding: 10px 0;
  display: none; /* Cacher les sous-menus par défaut */
  position: absolute; /* Positionnement absolu par rapport à l'élément parent */
  top: 100%; /* Positionner sous le lien principal */
  left: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.menu-item:hover .sub-menu {
  display: block; /* Afficher le sous-menu au survol */
}

.sub-menu .menu-item {
  margin: 15px;
  width: 75%;
}
.sub-menu li {
  padding-left: 12px !important;
  padding-right: 0px !important;
  width: 100%;
}
.et_pb_menu_0_tb_header.et_pb_menu .nav li ul {
  background-color: #ffffff !important;
  border-color: #bed630;
}

.sub-menu .menu-item > a {
  color: #555;
}
.nav li li {
  position: relative;
  line-height: 2em;
}
/* .nav li ul {
  position: absolute;
  padding: 20px 0;
  z-index: 9999;
  width: 240px;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  border-top: 3px solid #2ea3f2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
} */
@media all and (max-width: 600px) {
  .height {
    height: 20px;
  }
}
/* Styles pour les écrans mobiles */
/* Styles pour les écrans mobiles */
@media (max-width: 768px) {
  .button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #cccccc; /* Couleur de fond grise */
    color: #000000; /* Couleur du texte */
    text-decoration: none; /* Supprimer la décoration de lien par défaut */
    border-radius: 5px; /* Arrondir les coins */
    border: 1px solid #bbbbbb; /* Bordure grise */
    width: 329px;
    font-size: 14px !important;
  }
  .button-link:hover {
    background-color: #bbbbbb; /* Changer la couleur de fond au survol */
  }
  .et-menu.nav {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  .et-menu.nav.open {
    max-height: 500px; /* Ajustez la hauteur maximale en fonction du contenu */
    transition: max-height 0.5s ease-in;
  }
  .et-menu-nav {
    flex-direction: column; /* Aligner les éléments verticalement */
    align-items: flex-start;
  }

  .menu-toggle {
    display: block; /* Afficher le bouton de menu */
  }

  .et-menu.nav {
    flex-direction: column; /* Aligne les éléments verticalement */
    display: none; /* Cacher le menu par défaut */
  }

  .et-menu.nav.open {
    display: block; /* Afficher le menu lorsqu'il est ouvert */
    border-top: 3px solid #bed630;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    position: absolute;
    top: 100px;
    font-size: 10px;
  }

  .menu-item {
    margin-right: 0; /* Supprime l'espace horizontal entre les éléments */
    width: 100%; /* Prendre toute la largeur */
    margin: 18px;
  }

  .menu-item > a {
    display: block; /* Les liens prennent toute la largeur */
    font-weight: none !important;
  }
  .main-menu {
    display: none; /* Cacher les éléments principaux sur mobile */
  }
  .sub-menu {
    display: block; /* Afficher les sous-menus par défaut */
    position: static; /* Positionner de manière statique les sous-menus */
    border-top: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    top: 0% !important;
  }

  .sub-menu .menu-item > a {
    font-size: 14px; /* Taille de police réduite pour les sous-menus */
  }
  .menu-toggle {
    display: flex;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    width: 100%; /* Prend toute la largeur du conteneur parent */
    cursor: pointer; /* Affiche le curseur en pointeur pour indiquer l'interactivité */
  }
}
</style>
