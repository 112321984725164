export default {
  namespaced: true,
  state: {
    delay: 5000,
    errors: [],
    warnings: [],
    infos: [],
    success: [],
    moduleRedirection: {},
  },
  mutations: {
    addError(state, msg) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(msg)
      }
      state.errors.push(msg)
    },
    addWarning(state, msg) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(msg)
      }
      state.warnings.push(msg)
    },
    addInfo(state, msg) {
      if (process.env.NODE_ENV !== 'production') {
        console.info(msg)
      }
      state.infos.push(msg)
    },
    addSuccess(state, msg) {
      if (process.env.NODE_ENV !== 'production') {
        console.info(msg)
      }
      state.success.push(msg)
    },
    removeError(state, index = 0) {
      state.errors.splice(index, 1)
    },
    removeWarning(state, index = 0) {
      state.warnings.splice(index, 1)
    },
    removeInfo(state, index = 0) {
      state.infos.splice(index, 1)
    },
    removeSuccess(state, index = 0) {
      state.success.splice(index, 1)
    },
  },
  actions: {
    error(context, msg) {
      if (
        !context.state.errors.length ||
        context.state.errors[context.state.errors.length - 1] !== msg
      ) {
        context.commit('addError', msg)
        setTimeout(() => context.commit('removeError'), context.state.delay)
      }
    },
    warning(context, msg) {
      if (
        !context.state.warnings.length ||
        context.state.warnings[context.state.warnings.length - 1] !== msg
      ) {
        context.commit('addWarning', msg)
        setTimeout(() => context.commit('removeWarning'), context.state.delay)
      }
    },
    info(context, msg) {
      if (
        !context.state.infos.length ||
        context.state.infos[context.state.infos.length - 1] !== msg
      ) {
        context.commit('addInfo', msg)
        setTimeout(() => context.commit('removeInfo'), context.state.delay)
      }
    },
    success(context, msg) {
      if (
        !context.state.success.length ||
        context.state.success[context.state.success.length - 1] !== msg
      ) {
        context.commit('addSuccess', msg)
        setTimeout(() => context.commit('removeSuccess'), context.state.delay)
      }
    },
  },
}
