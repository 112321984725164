export const hypothesisConst = {
  location: {
    1: 'La réunion',
    2: 'Mayotte',
  },
  orientation: {
    1: 'Nord',
    2: 'Nord-Est',
    3: 'Est',
    4: 'Sud-Est',
    5: 'Sud',
    6: 'Sud-Ouest',
    7: 'Ouest',
  },
  criteria: {
    1: 'Confort visuel',
    2: 'Confort thermique',
  },
  piece: {
    1: ' Ventilation naturelle',
    2: 'Climatisée',
  },
}
