export default (app, store) => {
  app.config.globalProperties.$logError = function (message) {
    store.dispatch('log/error', message)
  }

  app.config.globalProperties.$logSuccess = function (message) {
    store.dispatch('log/success', message)
  }

  app.config.globalProperties.$logWarning = function (message) {
    store.dispatch('log/warning', message)
  }

  app.config.globalProperties.$logInfo = function (message) {
    store.dispatch('log/info', message)
  }
}
