export default {
  props: {
    modelValue: String,
  },
  methods: {
    onChange(value) {
      this.$emit('update:model-value', value)
    },
  },
}
