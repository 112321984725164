<template>
  <div>
    <v-progress-circular
      indeterminate
      :size="70"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'CustomProgressCircular',
}
</script>

<style scoped></style>
