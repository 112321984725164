import { createRouter, createWebHistory } from 'vue-router'
import BaseLayout from '@/Layout/BaseLayout'
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title:
        'Outil de Calcul Brise-Soleil - Protec Solame : Dimensionnement Précis',
    },
    component: BaseLayout,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to) => {
  document.title = to.meta.title || 'Protecsolame'
})

export default router
