<template>
  <v-expansion-panels v-model="panel" readonly>
    <v-expansion-panel>
      <v-expansion-panel-title>Résultats</v-expansion-panel-title>
      <result-panel :result="result" />
      <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="Résultats"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf"
      >
        <template v-slot:pdf-content>
          <pdf-result :result="result" />
        </template>
      </vue3-html2pdf>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-title
        >Abaques de dimensionnement</v-expansion-panel-title
      >
      <v-expansion-panel-text>
        <abaque-table />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <div class="d-flex flex-column justify-center">
    <v-btn
      block
      prepend-icon="mdi-arrow-u-right-bottom"
      stacked
      class="text-none mb-4"
      color="red-lighten-2"
      size="x-large"
      rounded="lg"
      @click="$emit('reset')"
    >
      Réinitialiser
    </v-btn>
    <v-btn
      block
      prepend-icon="mdi-file-document"
      stacked
      class="text-none mb-4"
      color="light-green-lighten-2"
      size="x-large"
      rounded="lg"
      @click="exportPdf"
    >
      Exporter PDF
    </v-btn>
  </div>
</template>

<script>
import Vue3Html2pdf from 'vue3-html2pdf'
import PdfResult from '@/components/calculate/PdfResult'
import ResultPanel from '@/components/calculate/ResultPanel'
import AbaqueTable from '@/components/calculate/AbaqueTable'
export default {
  name: 'CalculateResult',
  components: { AbaqueTable, ResultPanel, PdfResult, Vue3Html2pdf },
  props: {
    result: Object,
  },
  data() {
    return {
      panel: [0, 1, 2],
      efficiencyMetric: 0.75,
    }
  },
  methods: {
    exportPdf() {
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>

<style scoped>
.v-expansion-panel-text {
  display: block !important;
  overflow: scroll !important;
}
@media all and (max-width: 500px) {
  .v-col img {
    width: 300px !important;
  }
}
</style>
