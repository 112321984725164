<template>
  <v-row>
    <v-col cols="3">
      <v-select
        :model-value="modelValue"
        label="Orientation de votre ouverture à protéger"
        :items="orientations"
        item-value="value"
        item-title="title"
        variant="outlined"
        @update:modelValue="onChange"
      ></v-select>
    </v-col>
    <v-col cols="9">
      <img
        :src="getOrientationImage(modelValue)"
        width="150"
        alt="Orientation"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'OrientationStep',
  props: {
    modelValue: String,
  },
  data() {
    return {
      orientations: [
        { title: 'Nord', value: '1', img: 'boussole_Nord.svg' },
        { title: 'Nord-Est', value: '2', img: 'boussole_Nord_Est.svg' },
        { title: 'Est', value: '3', img: 'boussole_Est.svg' },
        { title: 'Sud-Est', value: '4', img: 'boussole_Sud_Est.svg' },
        { title: 'Sud', value: '5', img: 'boussole_Sud.svg' },
        { title: 'Sud-Ouest', value: '6', img: 'boussole_Sud_Ouest.svg' },
        { title: 'Ouest', value: '7', img: 'boussole_Ouest.svg' },
        { title: 'Nord-Ouest', value: '8', img: 'boussole_Nord_Ouest.svg' },
      ],
    }
  },
  methods: {
    onChange(value) {
      this.$emit('update:model-value', value)
    },
    getOrientationImage(value) {
      const orientation = this.orientations.find(
        (orientation) => orientation.value === value
      )
      if (orientation && orientation.img) {
        try {
          return require(`@/assets/orientation/${orientation.img}`)
        } catch (e) {
          console.error(`Error loading image: ${e}`)
          return ''
        }
      }
      return ''
    },
  },
}
</script>

<style scoped>
.v-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.v-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
@media all and (max-width: 500px) {
  .v-col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .v-col-9 {
    text-align: end;
  }
}
</style>
