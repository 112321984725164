<template>
  <v-expansion-panel-text>
    <v-row>
      <v-col>
        <v-card max-width="500" class="mx-auto">
          <v-card-item :class="{ 'pa-2': true, 'is-pdf result': isPdf }">
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-h6">Coefficient cm:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-green-accent-3 text-h5">{{
                  limitDecimal(result.ced)
                }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="px-4">
                <span class="text-h6">Autonomie en lumière naturelle : </span>
                <span class="text-green-accent-3 text-h5">
                  {{ formattedCel }}
                </span>
                <span> du temps avec lumière naturelle (en ciel dégagé)</span>
              </v-col>
            </v-row>

            <v-row v-if="result.piece_code !== '1'">
              <v-col cols="12" class="px-4">
                <span class="text-h6">Consommation annuelle associée : </span>
                <span class="text-green-accent-3 text-h5">
                  {{ formattedCec }}
                </span>
                <span>
                  consommation électrique annuelle évitée pour une pièce
                  climatisée à 25°C</span
                >
              </v-col>
            </v-row>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>

    <v-container :class="{ 'is-pdf': isPdf }">
      <v-card>
        <v-card-title class="text-h6"> Qualité du brise soleil </v-card-title>

        <v-card-subtitle>
          <v-row>
            <v-col :cols="levelCols" class="text-h6"
              >Niveau de protection (selon Cm) :</v-col
            >
            <v-col :cols="levelCols" class="text-h6"
              >Niveau d'éclairement :
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-item>
          <v-row>
            <v-col :cols="levelCols">
              <level-component :current-level="protectionLevel" />
            </v-col>
            <v-col :cols="levelCols">
              <level-component :current-level="lightingLevel" />
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>
  </v-expansion-panel-text>
</template>

<script>
import LevelComponent from '@/components/LevelComponent'
export default {
  name: 'ResultPanel',
  components: { LevelComponent },
  props: {
    result: Object,
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedCel() {
      const cel = this.result.cel * 100 ?? 0
      return this.limitDecimal(cel, 0) + '%'
    },
    formattedCec() {
      return this.limitDecimal(this.result.cec * 100, 0) + '%'
    },
    protectionLevel() {
      return this.result.protectionLevel ?? 1
    },
    lightingLevel() {
      return this.result.lightingLevel ?? 1
    },
    levelCols() {
      const largeScreen = this.$vuetify.display.smAndUp

      return largeScreen ? 6 : 12
    },
    facteurSolaire() {
      return (0.78 * this.result.ced).toFixed(2)
    },
  },
  methods: {
    limitDecimal(number, decimal = 2) {
      const dec = number.toString().split('.')[1]
      const len = dec && dec.length > 0 ? decimal : 0

      return number.toFixed(len)
    },
  },
}
</script>

<style scoped lang="sass">
.is-pdf
  padding-bottom: 0
.is-pdf.result
  .v-row
    margin-top: 5px !important
  .v-col
    padding-top: 0
    padding-bottom: 5px
</style>
