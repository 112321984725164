import axios from 'axios'

class Request {
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.instance.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          console.log(error.request)
        } else {
          console.log('Error', error.message)
        }
        console.log(error.config)
        throw error
      }
    )
  }

  get(url, params = {}) {
    return this.instance.get(url, { params })
  }

  post(url, data = {}) {
    return this.instance.post(url, data)
  }

  put(url, data = {}) {
    return this.instance.put(url, data)
  }

  delete(url, params = {}) {
    return this.instance.delete(url, { params })
  }
}

export default new Request()
