<template>
  <v-row>
    <v-col cols="2">
      <v-radio-group :model-value="modelValue" @update:modelValue="onChange">
        <v-radio label="La Réunion" value="1"></v-radio>
        <v-radio label="Mayotte" value="2"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="10">
      <img
        :src="require('@/assets/reunion_map.svg')"
        width="100"
        alt="La réunion"
        v-if="modelValue === '1'"
      />
      <img
        :src="require('@/assets/mayotte_map.svg')"
        width="100"
        alt="Mayotte"
        v-else
      />
    </v-col>
  </v-row>
</template>

<script>
import radioMixin from '@/mixins/radioMixin'

export default {
  name: 'LocationStep',
  mixins: [radioMixin],
}
</script>

<style scoped>
.v-selection-control .v-label {
  white-space: nowrap !important;
  word-break: break-word;
  height: 100%;
}
@media all and (max-width: 500px) {
  .selection-control .v-label {
    white-space: nowrap !important;
    word-break: break-word;
    /* height: 100%; */
  }
}
</style>
