<template>
  <v-container class="w-75 py-0 my-0">
    <v-row>
      <v-col>
        <img :src="require('@/assets/header-1.png')" height="60" alt="" />
      </v-col>
      <v-col>
        <div class="text-center">
          <img width="200" :src="require('@/assets/header-2.png')" alt="" />
        </div>
      </v-col>
      <v-col>
        <div class="d-flex flex-row justify-end">
          <img width="50" :src="require('@/assets/header-3.png')" alt="" />
          <img
            class="mx-1"
            width="50"
            :src="require('@/assets/header-4.png')"
            alt=""
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-expansion-panel-text class="py-0">
    <div class="mx-auto my-2 w-75 text-center pa-1">
      <h1 class="text-light-green-lighten-2">
        Simulation d'efficacité d'une protection solaire
      </h1>
    </div>

    <div class="mx-auto my-0 w-75">
      <div class="text-center">
        <h3 class="text-light-green-lighten-2">Hypothèse de calcul</h3>
      </div>

      <v-list lines="one">
        <v-list-item>
          <v-list-item-title
            >Localisation: {{ locationLabel }}</v-list-item-title
          >
        </v-list-item>

        <v-list-item>
          <v-list-item-title
            >Orientation: {{ orientationLabel }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>Critères: {{ criteriaLabel }} </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title
            >Information pièce: {{ pieceInformationLabel }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title
            >Caractéristique de la protection</v-list-item-title
          >

          <v-list-item-subtitle>
            <v-list>
              <v-list-item>Largeur (cm): {{ result.blade_width }}</v-list-item>
              <v-list-item
                >Espacement (cm): {{ result.blade_space }}</v-list-item
              >
              <v-list-item>Inclinaison (°): {{ result.tilt }}</v-list-item>
              <v-list-item
                >Surface occultée (%): {{ result.occultation }}</v-list-item
              >
            </v-list>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </div>

    <div class="mx-auto w-75">
      <div class="text-center">
        <img
          :src="require('@/assets/brise_soleil.png')"
          alt="Définition du brise soleil"
          width="200"
        />
      </div>
    </div>
  </v-expansion-panel-text>
  <result-panel :result="result" is-pdf />
</template>

<script>
import ResultPanel from '@/components/calculate/ResultPanel'
import { hypothesisConst } from '@/const/hypothesis.const'
export default {
  name: 'PdfResult',
  components: { ResultPanel },
  props: {
    result: Object,
  },
  computed: {
    locationLabel() {
      return hypothesisConst.location[this.result.meteo_code]
    },
    orientationLabel() {
      return hypothesisConst.orientation[this.result.orientation_code]
    },
    criteriaLabel() {
      return hypothesisConst.criteria[this.result.criteria_code]
    },
    pieceInformationLabel() {
      return hypothesisConst.piece[this.result.piece_code]
    },
  },
}
</script>

<style scoped lang="sass">
.v-list-item
  min-height: 0 !important

::v-deep(.v-expansion-panel-text__wrapper)
   padding-bottom: 0 !important
</style>
