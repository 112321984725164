import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const protecsolameTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'primary-darken-1': '#d6e4ed',
    'secondary-darken-1': '#018786',
    warning: '#FB8C00',
    primary: '#4bc1bd',
    secondary: '#3e5a73',
    accent: '#bed630',
    error: '#FF0000',
    success: '#00F235',
    info: '#F28300',
  },
}

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'protecsolameTheme',
    themes: {
      protecsolameTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})
