<template>
  <table id="sheet0" class="sheet0 gridlines">
    <col class="col0" />
    <col class="col1" />
    <col class="col2" />
    <col class="col3" />
    <col class="col4" />
    <col class="col5" />
    <col class="col6" />
    <col class="col7" />
    <col class="col8" />
    <col class="col9" />
    <col class="col10" />
    <col class="col11" />
    <col class="col12" />
    <col class="col13" />
    <col class="col14" />
    <col class="col15" />
    <col class="col16" />
    <col class="col17" />
    <col class="col18" />
    <col class="col19" />
    <col class="col20" />
    <col class="col21" />
    <col class="col22" />
    <col class="col23" />
    <col class="col24" />
    <col class="col25" />
    <col class="col26" />
    <col class="col27" />
    <col class="col28" />
    <col class="col29" />
    <col class="col30" />
    <col class="col31" />
    <tbody>
      <tr class="row0">
        <td class="column0 style155 s style155" colspan="2" rowspan="4">Cm</td>
        <td class="column2 style156 s style157" colspan="30">
          Largeur des lames
        </td>
      </tr>
      <tr class="row1">
        <td class="column2 style158 n style159" colspan="6">5</td>
        <td class="column8 style160 n style159" colspan="6">10</td>
        <td class="column14 style160 n style159" colspan="6">15</td>
        <td class="column20 style160 n style159" colspan="6">20</td>
        <td class="column26 style160 n style159" colspan="6">25</td>
      </tr>
      <tr class="row2">
        <td class="column2 style156 s style157" colspan="30">
          Inclinaison de la lame
        </td>
      </tr>
      <tr class="row3">
        <td class="column2 style1 n">0</td>
        <td class="column3 style1 n">10</td>
        <td class="column4 style1 n">20</td>
        <td class="column5 style1 n">30</td>
        <td class="column6 style1 n">40</td>
        <td class="column7 style1 n">50</td>
        <td class="column8 style1 n">0</td>
        <td class="column9 style1 n">10</td>
        <td class="column10 style1 n">20</td>
        <td class="column11 style1 n">30</td>
        <td class="column12 style1 n">40</td>
        <td class="column13 style1 n">50</td>
        <td class="column14 style1 n">0</td>
        <td class="column15 style1 n">10</td>
        <td class="column16 style1 n">20</td>
        <td class="column17 style1 n">30</td>
        <td class="column18 style1 n">40</td>
        <td class="column19 style1 n">50</td>
        <td class="column20 style1 n">0</td>
        <td class="column21 style1 n">10</td>
        <td class="column22 style1 n">20</td>
        <td class="column23 style1 n">30</td>
        <td class="column24 style1 n">40</td>
        <td class="column25 style1 n">50</td>
        <td class="column26 style1 n">0</td>
        <td class="column27 style1 n">10</td>
        <td class="column28 style1 n">20</td>
        <td class="column29 style1 n">30</td>
        <td class="column30 style1 n">40</td>
        <td class="column31 style1 n">50</td>
      </tr>
      <tr class="row4">
        <td class="column0 style154 s style154" rowspan="8">
          Espacement entre les lames
        </td>
        <td class="column1 style1 n">5</td>
        <td class="column2 style2 s">0.42</td>
        <td class="column3 style3 s">0.40</td>
        <td class="column4 style4 s">0.38</td>
        <td class="column5 style5 s">0.36</td>
        <td class="column6 style6 s">0.34</td>
        <td class="column7 style7 s">0.32</td>
        <td class="column8 style8 s">0.34</td>
        <td class="column9 style9 s">0.32</td>
        <td class="column10 style10 s">0.30</td>
        <td class="column11 style11 s">0.29</td>
        <td class="column12 style12 s">0.28</td>
        <td class="column13 style13 s">0.27</td>
        <td class="column14 style14 s">0.31</td>
        <td class="column15 style15 s">0.29</td>
        <td class="column16 style12 s">0.28</td>
        <td class="column17 style16 s">0.28</td>
        <td class="column18 style17 s">0.27</td>
        <td class="column19 style18 s">0.26</td>
        <td class="column20 style19 s">0.30</td>
        <td class="column21 style20 s">0.28</td>
        <td class="column22 style21 s">0.28</td>
        <td class="column23 style22 s">0.27</td>
        <td class="column24 style23 s">0.27</td>
        <td class="column25 style24 s">0.26</td>
        <td class="column26 style25 s">0.29</td>
        <td class="column27 style13 s">0.27</td>
        <td class="column28 style22 s">0.27</td>
        <td class="column29 style23 s">0.27</td>
        <td class="column30 style26 s">0.26</td>
        <td class="column31 style27 s">0.26</td>
      </tr>
      <tr class="row5">
        <td class="column1 style1 n">10</td>
        <td class="column2 style28 s">0.58</td>
        <td class="column3 style29 s">0.56</td>
        <td class="column4 style30 s">0.54</td>
        <td class="column5 style31 s">0.53</td>
        <td class="column6 style32 s">0.53</td>
        <td class="column7 style33 s">0.52</td>
        <td class="column8 style34 s">0.45</td>
        <td class="column9 style35 s">0.43</td>
        <td class="column10 style36 s">0.40</td>
        <td class="column11 style37 s">0.38</td>
        <td class="column12 style38 s">0.36</td>
        <td class="column13 style39 s">0.34</td>
        <td class="column14 style40 s">0.40</td>
        <td class="column15 style41 s">0.37</td>
        <td class="column16 style42 s">0.35</td>
        <td class="column17 style43 s">0.33</td>
        <td class="column18 style44 s">0.31</td>
        <td class="column19 style15 s">0.29</td>
        <td class="column20 style45 s">0.36</td>
        <td class="column21 style46 s">0.34</td>
        <td class="column22 style47 s">0.32</td>
        <td class="column23 style48 s">0.30</td>
        <td class="column24 style49 s">0.29</td>
        <td class="column25 style20 s">0.28</td>
        <td class="column26 style6 s">0.34</td>
        <td class="column27 style47 s">0.32</td>
        <td class="column28 style50 s">0.30</td>
        <td class="column29 style49 s">0.29</td>
        <td class="column30 style12 s">0.28</td>
        <td class="column31 style17 s">0.27</td>
      </tr>
      <tr class="row6">
        <td class="column1 style1 n">15</td>
        <td class="column2 style51 s">0.67</td>
        <td class="column3 style52 s">0.66</td>
        <td class="column4 style53 s">0.65</td>
        <td class="column5 style54 s">0.65</td>
        <td class="column6 style55 s">0.65</td>
        <td class="column7 style56 s">0.65</td>
        <td class="column8 style57 s">0.54</td>
        <td class="column9 style58 s">0.52</td>
        <td class="column10 style59 s">0.50</td>
        <td class="column11 style60 s">0.48</td>
        <td class="column12 style61 s">0.46</td>
        <td class="column13 style62 s">0.44</td>
        <td class="column14 style63 s">0.47</td>
        <td class="column15 style64 s">0.44</td>
        <td class="column16 style65 s">0.42</td>
        <td class="column17 style66 s">0.39</td>
        <td class="column18 style67 s">0.37</td>
        <td class="column19 style8 s">0.34</td>
        <td class="column20 style68 s">0.42</td>
        <td class="column21 style69 s">0.39</td>
        <td class="column22 style41 s">0.37</td>
        <td class="column23 style8 s">0.35</td>
        <td class="column24 style43 s">0.33</td>
        <td class="column25 style70 s">0.31</td>
        <td class="column26 style69 s">0.39</td>
        <td class="column27 style71 s">0.36</td>
        <td class="column28 style72 s">0.34</td>
        <td class="column29 style73 s">0.32</td>
        <td class="column30 style70 s">0.31</td>
        <td class="column31 style49 s">0.29</td>
      </tr>
      <tr class="row7">
        <td class="column1 style1 n">20</td>
        <td class="column2 style74 s">0.74</td>
        <td class="column3 style74 s">0.73</td>
        <td class="column4 style74 s">0.72</td>
        <td class="column5 style74 s">0.72</td>
        <td class="column6 style74 s">0.72</td>
        <td class="column7 style74 s">0.73</td>
        <td class="column8 style75 s">0.61</td>
        <td class="column9 style76 s">0.59</td>
        <td class="column10 style77 s">0.57</td>
        <td class="column11 style78 s">0.56</td>
        <td class="column12 style79 s">0.55</td>
        <td class="column13 style80 s">0.54</td>
        <td class="column14 style81 s">0.53</td>
        <td class="column15 style82 s">0.50</td>
        <td class="column16 style83 s">0.48</td>
        <td class="column17 style84 s">0.46</td>
        <td class="column18 style85 s">0.44</td>
        <td class="column19 style65 s">0.41</td>
        <td class="column20 style60 s">0.48</td>
        <td class="column21 style86 s">0.45</td>
        <td class="column22 style2 s">0.42</td>
        <td class="column23 style87 s">0.40</td>
        <td class="column24 style88 s">0.37</td>
        <td class="column25 style89 s">0.35</td>
        <td class="column26 style64 s">0.44</td>
        <td class="column27 style90 s">0.41</td>
        <td class="column28 style37 s">0.38</td>
        <td class="column29 style91 s">0.36</td>
        <td class="column30 style92 s">0.34</td>
        <td class="column31 style47 s">0.32</td>
      </tr>
      <tr class="row8">
        <td class="column1 style1 n">25</td>
        <td class="column2 style74 s">0.78</td>
        <td class="column3 style74 s">0.77</td>
        <td class="column4 style74 s">0.77</td>
        <td class="column5 style74 s">0.77</td>
        <td class="column6 style74 s">0.77</td>
        <td class="column7 style74 s">0.78</td>
        <td class="column8 style93 s">0.67</td>
        <td class="column9 style55 s">0.65</td>
        <td class="column10 style94 s">0.63</td>
        <td class="column11 style95 s">0.62</td>
        <td class="column12 style96 s">0.61</td>
        <td class="column13 style75 s">0.61</td>
        <td class="column14 style97 s">0.58</td>
        <td class="column15 style78 s">0.56</td>
        <td class="column16 style98 s">0.54</td>
        <td class="column17 style99 s">0.52</td>
        <td class="column18 style59 s">0.50</td>
        <td class="column19 style100 s">0.48</td>
        <td class="column20 style101 s">0.52</td>
        <td class="column21 style102 s">0.50</td>
        <td class="column22 style103 s">0.47</td>
        <td class="column23 style104 s">0.45</td>
        <td class="column24 style105 s">0.42</td>
        <td class="column25 style106 s">0.40</td>
        <td class="column26 style107 s">0.48</td>
        <td class="column27 style108 s">0.45</td>
        <td class="column28 style68 s">0.42</td>
        <td class="column29 style109 s">0.40</td>
        <td class="column30 style110 s">0.37</td>
        <td class="column31 style111 s">0.35</td>
      </tr>
      <tr class="row9">
        <td class="column1 style1 n">30</td>
        <td class="column2 style74 s">0.81</td>
        <td class="column3 style74 s">0.81</td>
        <td class="column4 style74 s">0.81</td>
        <td class="column5 style74 s">0.81</td>
        <td class="column6 style74 s">0.81</td>
        <td class="column7 style74 s">0.81</td>
        <td class="column8 style74 s">0.71</td>
        <td class="column9 style112 s">0.69</td>
        <td class="column10 style113 s">0.68</td>
        <td class="column11 style114 s">0.67</td>
        <td class="column12 style93 s">0.67</td>
        <td class="column13 style115 s">0.67</td>
        <td class="column14 style116 s">0.63</td>
        <td class="column15 style117 s">0.60</td>
        <td class="column16 style118 s">0.58</td>
        <td class="column17 style119 s">0.57</td>
        <td class="column18 style120 s">0.55</td>
        <td class="column19 style79 s">0.55</td>
        <td class="column20 style119 s">0.57</td>
        <td class="column21 style80 s">0.54</td>
        <td class="column22 style121 s">0.51</td>
        <td class="column23 style122 s">0.49</td>
        <td class="column24 style103 s">0.47</td>
        <td class="column25 style34 s">0.45</td>
        <td class="column26 style33 s">0.52</td>
        <td class="column27 style122 s">0.49</td>
        <td class="column28 style123 s">0.47</td>
        <td class="column29 style124 s">0.44</td>
        <td class="column30 style65 s">0.42</td>
        <td class="column31 style125 s">0.39</td>
      </tr>
      <tr class="row10">
        <td class="column1 style1 n">35</td>
        <td class="column2 style74 s">0.84</td>
        <td class="column3 style74 s">0.83</td>
        <td class="column4 style74 s">0.83</td>
        <td class="column5 style74 s">0.83</td>
        <td class="column6 style74 s">0.83</td>
        <td class="column7 style74 s">0.84</td>
        <td class="column8 style74 s">0.74</td>
        <td class="column9 style74 s">0.73</td>
        <td class="column10 style74 s">0.72</td>
        <td class="column11 style74 s">0.71</td>
        <td class="column12 style74 s">0.71</td>
        <td class="column13 style74 s">0.71</td>
        <td class="column14 style52 s">0.66</td>
        <td class="column15 style126 s">0.64</td>
        <td class="column16 style116 s">0.62</td>
        <td class="column17 style75 s">0.61</td>
        <td class="column18 style127 s">0.60</td>
        <td class="column19 style128 s">0.60</td>
        <td class="column20 style127 s">0.60</td>
        <td class="column21 style129 s">0.58</td>
        <td class="column22 style130 s">0.55</td>
        <td class="column23 style98 s">0.53</td>
        <td class="column24 style99 s">0.52</td>
        <td class="column25 style131 s">0.50</td>
        <td class="column26 style130 s">0.55</td>
        <td class="column27 style132 s">0.53</td>
        <td class="column28 style133 s">0.50</td>
        <td class="column29 style60 s">0.48</td>
        <td class="column30 style84 s">0.46</td>
        <td class="column31 style134 s">0.44</td>
      </tr>
      <tr class="row11">
        <td class="column1 style1 n">40</td>
        <td class="column2 style135 s">0.85</td>
        <td class="column3 style135 s">0.85</td>
        <td class="column4 style135 s">0.85</td>
        <td class="column5 style135 s">0.85</td>
        <td class="column6 style135 s">0.85</td>
        <td class="column7 style135 s">0.86</td>
        <td class="column8 style135 s">0.77</td>
        <td class="column9 style135 s">0.75</td>
        <td class="column10 style135 s">0.75</td>
        <td class="column11 style135 s">0.74</td>
        <td class="column12 style135 s">0.74</td>
        <td class="column13 style135 s">0.74</td>
        <td class="column14 style136 s">0.69</td>
        <td class="column15 style137 s">0.67</td>
        <td class="column16 style138 s">0.66</td>
        <td class="column17 style139 s">0.65</td>
        <td class="column18 style140 s">0.64</td>
        <td class="column19 style141 s">0.64</td>
        <td class="column20 style142 s">0.63</td>
        <td class="column21 style143 s">0.61</td>
        <td class="column22 style144 s">0.59</td>
        <td class="column23 style145 s">0.57</td>
        <td class="column24 style146 s">0.56</td>
        <td class="column25 style147 s">0.55</td>
        <td class="column26 style148 s">0.59</td>
        <td class="column27 style149 s">0.56</td>
        <td class="column28 style150 s">0.54</td>
        <td class="column29 style151 s">0.51</td>
        <td class="column30 style152 s">0.49</td>
        <td class="column31 style153 s">0.48</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'AbaqueTable',
}
</script>

<style scoped>
td.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: 'Calibri';
  font-size: 11pt;
  background-color: white;
}
th.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: 'Calibri';
  font-size: 11pt;
  background-color: white;
}
td.style1 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ffffff;
}
th.style1 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ffffff;
}
td.style2 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcbe7b;
}
th.style2 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcbe7b;
}
td.style3 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb379;
}
th.style3 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb379;
}
td.style4 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba977;
}
th.style4 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba977;
}
td.style5 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9f75;
}
th.style5 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9f75;
}
td.style6 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9573;
}
th.style6 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9573;
}
td.style7 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98b71;
}
th.style7 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98b71;
}
td.style8 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9673;
}
th.style8 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9673;
}
td.style9 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98a71;
}
th.style9 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98a71;
}
td.style10 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9816f;
}
th.style10 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9816f;
}
td.style11 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f87b6e;
}
th.style11 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f87b6e;
}
td.style12 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8766d;
}
th.style12 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8766d;
}
td.style13 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8716c;
}
th.style13 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8716c;
}
td.style14 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98670;
}
th.style14 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98670;
}
td.style15 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f97b6e;
}
th.style15 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f97b6e;
}
td.style16 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8736d;
}
th.style16 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8736d;
}
td.style17 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8706c;
}
th.style17 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8706c;
}
td.style18 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86c6b;
}
th.style18 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86c6b;
}
td.style19 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f97e6f;
}
th.style19 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f97e6f;
}
td.style20 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8746d;
}
th.style20 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8746d;
}
td.style21 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8726c;
}
th.style21 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8726c;
}
td.style22 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86f6c;
}
th.style22 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86f6c;
}
td.style23 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86d6b;
}
th.style23 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86d6b;
}
td.style24 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86a6b;
}
th.style24 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86a6b;
}
td.style25 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8796e;
}
th.style25 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8796e;
}
td.style26 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86b6b;
}
th.style26 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f86b6b;
}
td.style27 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8696b;
}
th.style27 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8696b;
}
td.style28 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e0e283;
}
th.style28 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e0e283;
}
td.style29 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e7e583;
}
th.style29 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e7e583;
}
td.style30 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #eee683;
}
th.style30 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #eee683;
}
td.style31 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f3e884;
}
th.style31 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f3e884;
}
td.style32 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f6e984;
}
th.style32 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f6e984;
}
td.style33 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8e984;
}
th.style33 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f8e984;
}
td.style34 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd07e;
}
th.style34 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd07e;
}
td.style35 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcc27c;
}
th.style35 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcc27c;
}
td.style36 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb679;
}
th.style36 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb679;
}
td.style37 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbaa77;
}
th.style37 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbaa77;
}
td.style38 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9e75;
}
th.style38 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9e75;
}
td.style39 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9273;
}
th.style39 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9273;
}
td.style40 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb179;
}
th.style40 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb179;
}
td.style41 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba376;
}
th.style41 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba376;
}
td.style42 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9773;
}
th.style42 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9773;
}
td.style43 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98c71;
}
th.style43 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98c71;
}
td.style44 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98370;
}
th.style44 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98370;
}
td.style45 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #faa075;
}
th.style45 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #faa075;
}
td.style46 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9272;
}
th.style46 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9272;
}
td.style47 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98770;
}
th.style47 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98770;
}
td.style48 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9806f;
}
th.style48 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9806f;
}
td.style49 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f87a6e;
}
th.style49 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f87a6e;
}
td.style50 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f97f6f;
}
th.style50 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f97f6f;
}
td.style51 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b5d680;
}
th.style51 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b5d680;
}
td.style52 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bad780;
}
th.style52 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bad780;
}
td.style53 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bdd881;
}
th.style53 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bdd881;
}
td.style54 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bfd981;
}
th.style54 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bfd981;
}
td.style55 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c0d981;
}
th.style55 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c0d981;
}
td.style56 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bed981;
}
th.style56 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bed981;
}
td.style57 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #eee784;
}
th.style57 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #eee784;
}
td.style58 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9ea84;
}
th.style58 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9ea84;
}
td.style59 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee783;
}
th.style59 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee783;
}
td.style60 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedc81;
}
th.style60 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedc81;
}
td.style61 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd37f;
}
th.style61 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd37f;
}
td.style62 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdca7d;
}
th.style62 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdca7d;
}
td.style63 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd880;
}
th.style63 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd880;
}
td.style64 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc97d;
}
th.style64 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc97d;
}
td.style65 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcbb7a;
}
th.style65 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcbb7a;
}
td.style66 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbae78;
}
th.style66 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbae78;
}
td.style67 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba276;
}
th.style67 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba276;
}
td.style68 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcc07b;
}
th.style68 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcc07b;
}
td.style69 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb078;
}
th.style69 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb078;
}
td.style70 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9826f;
}
th.style70 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f9826f;
}
td.style71 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba075;
}
th.style71 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba075;
}
td.style72 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9373;
}
th.style72 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9373;
}
td.style73 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98971;
}
th.style73 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f98971;
}
td.style74 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #000000;
}
th.style74 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #000000;
}
td.style75 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d0de82;
}
th.style75 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d0de82;
}
td.style76 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d9e082;
}
th.style76 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d9e082;
}
td.style77 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e1e383;
}
th.style77 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e1e383;
}
td.style78 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e8e583;
}
th.style78 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e8e583;
}
td.style79 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ede683;
}
th.style79 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ede683;
}
td.style80 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f0e784;
}
th.style80 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f0e784;
}
td.style81 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f4e884;
}
th.style81 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f4e884;
}
td.style82 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #feea83;
}
th.style82 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #feea83;
}
td.style83 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedd81;
}
th.style83 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedd81;
}
td.style84 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd17f;
}
th.style84 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd17f;
}
td.style85 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc67c;
}
th.style85 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc67c;
}
td.style86 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdcd7e;
}
th.style86 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdcd7e;
}
td.style87 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb178;
}
th.style87 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb178;
}
td.style88 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba476;
}
th.style88 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba476;
}
td.style89 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9774;
}
th.style89 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9774;
}
td.style90 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb97a;
}
th.style90 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb97a;
}
td.style91 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9d75;
}
th.style91 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9d75;
}
td.style92 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9172;
}
th.style92 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9172;
}
td.style93 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b8d780;
}
th.style93 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b8d780;
}
td.style94 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c7db81;
}
th.style94 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c7db81;
}
td.style95 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ccdd82;
}
th.style95 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ccdd82;
}
td.style96 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #cfdd82;
}
th.style96 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #cfdd82;
}
td.style97 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dde283;
}
th.style97 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dde283;
}
td.style98 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f2e884;
}
th.style98 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f2e884;
}
td.style99 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #faea84;
}
th.style99 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #faea84;
}
td.style100 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee081;
}
th.style100 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee081;
}
td.style101 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f7e984;
}
th.style101 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f7e984;
}
td.style102 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee683;
}
th.style102 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee683;
}
td.style103 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fed980;
}
th.style103 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fed980;
}
td.style104 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdcc7e;
}
th.style104 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdcc7e;
}
td.style105 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcbf7b;
}
th.style105 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcbf7b;
}
td.style106 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb479;
}
th.style106 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fcb479;
}
td.style107 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedf81;
}
th.style107 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedf81;
}
td.style108 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdcf7e;
}
th.style108 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdcf7e;
}
td.style109 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb379;
}
th.style109 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbb379;
}
td.style110 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba576;
}
th.style110 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fba576;
}
td.style111 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9974;
}
th.style111 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fa9974;
}
td.style112 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #add480;
}
th.style112 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #add480;
}
td.style113 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b2d580;
}
th.style113 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b2d580;
}
td.style114 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b6d680;
}
th.style114 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b6d680;
}
td.style115 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b7d780;
}
th.style115 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b7d780;
}
td.style116 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #cadc81;
}
th.style116 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #cadc81;
}
td.style117 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d4df82;
}
th.style117 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d4df82;
}
td.style118 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dce182;
}
th.style118 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dce182;
}
td.style119 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e4e483;
}
th.style119 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e4e483;
}
td.style120 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #eae583;
}
th.style120 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #eae583;
}
td.style121 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbea84;
}
th.style121 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbea84;
}
td.style122 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee482;
}
th.style122 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee482;
}
td.style123 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd680;
}
th.style123 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdd680;
}
td.style124 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc87d;
}
th.style124 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc87d;
}
td.style125 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbaf78;
}
th.style125 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbaf78;
}
td.style126 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c3da81;
}
th.style126 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c3da81;
}
td.style127 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d5df82;
}
th.style127 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d5df82;
}
td.style128 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d7e082;
}
th.style128 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d7e082;
}
td.style129 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dfe283;
}
th.style129 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dfe283;
}
td.style130 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e9e583;
}
th.style130 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e9e583;
}
td.style131 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ffeb84;
}
th.style131 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ffeb84;
}
td.style132 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f5e984;
}
th.style132 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f5e984;
}
td.style133 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee983;
}
th.style133 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee983;
}
td.style134 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc67d;
}
th.style134 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fdc67d;
}
td.style135 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #000000;
}
th.style135 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #000000;
}
td.style136 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #acd480;
}
th.style136 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #acd480;
}
td.style137 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b4d680;
}
th.style137 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #b4d680;
}
td.style138 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bbd881;
}
th.style138 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #bbd881;
}
td.style139 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c0d981;
}
th.style139 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c0d981;
}
td.style140 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c3da81;
}
th.style140 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c3da81;
}
td.style141 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c4da81;
}
th.style141 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c4da81;
}
td.style142 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c6db81;
}
th.style142 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #c6db81;
}
td.style143 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d1de82;
}
th.style143 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #d1de82;
}
td.style144 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dae182;
}
th.style144 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dae182;
}
td.style145 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e2e383;
}
th.style145 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e2e383;
}
td.style146 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e8e583;
}
th.style146 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e8e583;
}
td.style147 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ece683;
}
th.style147 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ece683;
}
td.style148 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dce182;
}
th.style148 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #dce182;
}
td.style149 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e7e583;
}
th.style149 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #e7e583;
}
td.style150 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f2e884;
}
th.style150 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #f2e884;
}
td.style151 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbea84;
}
th.style151 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fbea84;
}
td.style152 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee582;
}
th.style152 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fee582;
}
td.style153 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedc81;
}
th.style153 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #fedc81;
}
td.style154 {
  vertical-align: middle;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
th.style154 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
td.style155 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
th.style155 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
td.style156 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
th.style156 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
td.style157 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
th.style157 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
}
td.style158 {
  vertical-align: bottom;
  text-align: center;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  background-color: #ffffff;
}
th.style158 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  background-color: #ffffff;
}
td.style159 {
  vertical-align: bottom;
  text-align: center;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ffffff;
}
th.style159 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  color: #000000;
  background-color: #ffffff;
}
td.style160 {
  vertical-align: bottom;
  text-align: center;
  border-right: none #000000;
  color: #000000;
  background-color: #ffffff;
}
th.style160 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  background-color: #ffffff;
}
table.sheet0 col.col0 {
  width: 42pt;
}
table.sheet0 col.col1 {
  width: 42pt;
}
table.sheet0 col.col2 {
  width: 42pt;
}
table.sheet0 col.col3 {
  width: 42pt;
}
table.sheet0 col.col4 {
  width: 42pt;
}
table.sheet0 col.col5 {
  width: 42pt;
}
table.sheet0 col.col6 {
  width: 42pt;
}
table.sheet0 col.col7 {
  width: 42pt;
}
table.sheet0 col.col8 {
  width: 42pt;
}
table.sheet0 col.col9 {
  width: 42pt;
}
table.sheet0 col.col10 {
  width: 42pt;
}
table.sheet0 col.col11 {
  width: 42pt;
}
table.sheet0 col.col12 {
  width: 42pt;
}
table.sheet0 col.col13 {
  width: 42pt;
}
table.sheet0 col.col14 {
  width: 42pt;
}
table.sheet0 col.col15 {
  width: 42pt;
}
table.sheet0 col.col16 {
  width: 42pt;
}
table.sheet0 col.col17 {
  width: 42pt;
}
table.sheet0 col.col18 {
  width: 42pt;
}
table.sheet0 col.col19 {
  width: 42pt;
}
table.sheet0 col.col20 {
  width: 42pt;
}
table.sheet0 col.col21 {
  width: 42pt;
}
table.sheet0 col.col22 {
  width: 42pt;
}
table.sheet0 col.col23 {
  width: 42pt;
}
table.sheet0 col.col24 {
  width: 42pt;
}
table.sheet0 col.col25 {
  width: 42pt;
}
table.sheet0 col.col26 {
  width: 42pt;
}
table.sheet0 col.col27 {
  width: 42pt;
}
table.sheet0 col.col28 {
  width: 42pt;
}
table.sheet0 col.col29 {
  width: 42pt;
}
table.sheet0 col.col30 {
  width: 42pt;
}
table.sheet0 col.col31 {
  width: 42pt;
}
table.sheet0 tr {
  height: 15pt;
}
table.sheet0 tr.row0 {
  height: 15.75pt;
}
table.sheet0 tr.row1 {
  height: 15.75pt;
}
table.sheet0 tr.row2 {
  height: 15.75pt;
}
table.sheet0 tr.row3 {
  height: 15.75pt;
}
table.sheet0 tr.row4 {
  height: 15.75pt;
}
table.sheet0 tr.row5 {
  height: 15.75pt;
}
table.sheet0 tr.row6 {
  height: 15.75pt;
}
table.sheet0 tr.row7 {
  height: 15.75pt;
}
table.sheet0 tr.row8 {
  height: 15.75pt;
}
table.sheet0 tr.row9 {
  height: 15.75pt;
}
table.sheet0 tr.row10 {
  height: 15.75pt;
}
table.sheet0 tr.row11 {
  height: 15.75pt;
}
</style>
