<template>
  <v-alert
    :timeout="timeout"
    density="compact"
    type="error"
    variant="outlined"
    v-for="(message, index) in $store.state.log.errors"
    :key="`${index}-error`"
    closable
  >
    {{ message }}
  </v-alert>

  <v-alert
    :timeout="timeout"
    density="compact"
    type="success"
    variant="outlined"
    v-for="(message, index) in $store.state.log.success"
    :key="`${index}-success`"
    closable
  >
    {{ message }}
  </v-alert>

  <v-alert
    :timeout="timeout"
    density="compact"
    type="warning"
    variant="outlined"
    v-for="(message, index) in $store.state.log.warnings"
    :key="`${index}-warning`"
    closable
  >
    {{ message }}
  </v-alert>

  <v-alert
    :timeout="timeout"
    density="compact"
    type="info"
    variant="outlined"
    v-for="(message, index) in $store.state.log.infos"
    :key="`${index}-info`"
    closable
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'BaseLogger',
  computed: {
    timeout() {
      return this.$store.state.log.timeout
    },
  },
}
</script>

<style scoped></style>
