<template>
  <v-radio-group :model-value="modelValue" @update:modelValue="onChange">
    <v-radio label="Confort visuel" value="1">
      <template v-slot:label="{ label }">
        {{ label }}
        <v-tooltip
          contained="true"
          max-width="500"
          activator="parent"
          location="end"
        >
          Vous pouvez indiquer si vous préférez privilégier le "confort visuel"
          ou le" confort thermique". Cette sélection déterminera les données à
          afficher, soit celles de février pour le confort thermique, soit
          celles de juin pour le confort visuel.</v-tooltip
        >
      </template>
    </v-radio>
    <v-radio label="Confort thermique" value="2">
      <template v-slot:label="{ label }">
        {{ label }}
        <v-tooltip
          contained="true"
          max-width="500"
          activator="parent"
          location="end"
        >
          En sélectionnant l'option de la pièce climatisée, vous pourrez
          consulter le gain en consommation électrique annuelle de la
          climatisation. Opter pour la 'Ventilation naturelle' fera disparaître
          cette information.</v-tooltip
        >
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import radioMixin from '@/mixins/radioMixin'

export default {
  name: 'CriteriaStep',
  mixins: [radioMixin],
}
</script>

<style scoped></style>
