<template>
  <canvas id="canvas" width="600" height="350"></canvas>
</template>
<script>
import { Canvas, Rect, Textbox } from 'fabric'
export default {
  name: 'ThreeScene',
  props: ['largeur', 'espacement', 'inclinaison', 'occule'],
  data() {
    return {
      objet1: '',
      objet2: '',
      canvas: '',
      objetOccul: '',
      nombreDeDiviseurs: 0,
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    espacement(newVal, oldVal) {
      console.log(`Espacement changé de ${oldVal} à ${newVal}`)
      this.objet2.set({
        top: this.echellePosition(newVal),
      })
      this.canvas.renderAll()
      // Logique à exécuter lorsque espacement change
      // Supprimer les anciennes divisions (si elles existent)
      // Supprimer les anciennes divisions
      this.objetOccul.divisions.forEach((division) =>
        this.canvas.remove(division)
      )
      this.objetOccul.divisions = []
      this.nombreDeDiviseurs = this.getDiviseur(parseInt(newVal))
      console.log('nobmre0', this.nombreDeDiviseurs)
      // Créer les nouvelles divisions
      const divisionHeight = this.objetOccul.height / this.nombreDeDiviseurs
      for (let i = 0; i < this.nombreDeDiviseurs; i++) {
        const rect = new Rect({
          left: 260,
          width: 120,
          top: 110 + i * divisionHeight,
          height: divisionHeight,
          fill: 'transparent',
          stroke: 'red',
          isDivision: true,
        })
        this.objetOccul.divisions.push(rect)
        this.canvas.add(rect)
      }
      this.canvas.renderAll()
    },
    inclinaison(newVal, oldVal) {
      console.log(`Inclinaison changée de ${oldVal} à ${newVal}`)
      this.objet1.set({
        angle: newVal,
      })
      this.objet2.set({
        angle: newVal,
      })
      this.canvas.renderAll()
      // Logique à exécuter lorsque inclinaison change
    },
    largeur(newVal, oldVal) {
      console.log(`Largeur changée de ${oldVal} à ${newVal}`)
      this.objet1.set({
        width: this.echelleLargeur(newVal),
      })
      this.objet2.set({
        width: this.echelleLargeur(newVal),
      })
      this.canvas.renderAll()
      // Logique à exécuter lorsque largeur change
    },
    occule(newVal, oldVal) {
      console.log(`Occultation changée de ${oldVal} à ${newVal}`)
      console.log(((160 * newVal) / 100).toFixed(0))
      var newHeight = ((160 * newVal) / 100).toFixed(0) * 1
      this.objetOccul.set({
        height: newHeight,
      })
      this.canvas.renderAll()
      this.divisionOccultation()

      // Logique à exécuter lorsque occultation change
    },
  },
  methods: {
    divisionOccultation() {
      // Logique à exécuter lorsque espacement change
      // Supprimer les anciennes divisions (si elles existent)
      // Supprimer les anciennes divisions
      this.objetOccul.divisions.forEach((division) =>
        this.canvas.remove(division)
      )
      this.objetOccul.divisions = []
      this.nombreDeDiviseurs = this.getDiviseur(parseInt(this.espacement))
      console.log('nobmre0', this.nombreDeDiviseurs)
      // Créer les nouvelles divisions
      const divisionHeight = this.objetOccul.height / this.nombreDeDiviseurs
      for (let i = 0; i < this.nombreDeDiviseurs; i++) {
        const rect = new Rect({
          left: 260,
          width: 120,
          top: 110 + i * divisionHeight,
          height: divisionHeight,
          fill: 'transparent',
          stroke: 'red',
          isDivision: true,
        })
        this.objetOccul.divisions.push(rect)
        this.canvas.add(rect)
      }
    },
    getDiviseur(newval) {
      console.log('new val', newval)
      if (newval === 40) {
        return 4
      } else if (newval === 35) {
        return 5
      } else if (newval === 30) {
        return 6
      } else if (newval === 25) {
        return 7
      } else if (newval === 20) {
        return 8
      } else if (newval === 15) {
        return 9
      } else if (newval === 10) {
        return 10
      } else if (newval === 5) {
        return 30
      }
    },
    echelleLargeur(x) {
      return -4 * x - 10
    },
    echellePosition(x) {
      return 4 * x + 120
    },
    createObjet() {
      this.objet1 = new Rect({
        left: 170,
        top: 110,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 2,
        width: this.echelleLargeur(this.largeur),
        height: 20,
        angle: this.inclinaison,
        selectable: false,
      })
      this.canvas.add(this.objet1)
      this.objet2 = new Rect({
        left: 170,
        top: this.echellePosition(this.espacement),
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 2,
        width: this.echelleLargeur(this.largeur),
        height: 20,
        angle: this.inclinaison,
        selectable: false,
      })
      this.canvas.add(this.objet2)
      this.objetOccul = new Rect({
        left: 260,
        top: 110,
        stroke: 'red',
        strokeWidth: 2,
        fill: 'transparent',
        width: 120,
        height: ((160 * this.occule) / 100).toFixed(0) * 1,
        selectable: false,
        divisions: [],
      })

      // Ajout de l'objet cadreOcculation au canevas
      this.canvas.add(this.objetOccul)
    },
    init() {
      this.canvas = new Canvas('canvas')
      // Exemple d'ajout d'un rectangle
      const scene = new Rect({
        left: 0,
        top: 0,
        fill: 'transparent',
        stroke: 'black',
        width: 440,
        height: 320,
        selectable: false,
      })

      this.canvas.add(scene)
      const separated = new Rect({
        left: 200,
        top: 0,
        stroke: 'black',
        fill: 'transparent',
        width: 0,
        height: 320,
        selectable: false,
      })

      this.canvas.add(separated)
      const interieur = new Rect({
        left: 20,
        top: -5,
        stroke: '#4bc1bd',
        fill: 'transparent',
        width: 0,
        height: 320,
        strokeWidth: 8,
        selectable: false,
      })

      this.canvas.add(interieur)
      const textInt = new Textbox('INT.', {
        left: 2,
        top: 130,
        fontSize: 10,
        fill: 'noir',
        selectable: false,
      })

      // Ajout de l'objet Textbox au canevas
      this.canvas.add(textInt)
      const textExt = new Textbox('EXT.', {
        left: 175,
        top: 130,
        fontSize: 10,
        fill: 'noir',
        selectable: false,
      })

      // Ajout de l'objet Textbox au canevas
      this.canvas.add(textExt)
      const visualisation = new Textbox('Visualisation 2D', {
        left: 50,
        top: 0,
        width: 200,
        fontSize: 15,
        fill: 'noir',
        selectable: false,
      })

      // Ajout de l'objet Textbox au canevas
      this.canvas.add(visualisation)
      const cadreOcculation = new Rect({
        left: 220,
        top: 70,
        stroke: 'black',
        fill: 'transparent',
        width: 190,
        height: 230,
        selectable: false,
      })

      // Ajout de l'objet cadreOcculation au canevas
      this.canvas.add(cadreOcculation)
      const bas = new Rect({
        left: 260,
        top: 110,
        stroke: '#4bc1bd',
        strokeWidth: 2,
        fill: 'transparent',
        width: 120,
        height: 160,
        selectable: false,
      })

      // Ajout de l'objet cadreOcculation au canevas
      this.canvas.add(bas)
      const textBas = new Textbox('Bas', {
        left: 300,
        top: 300,
        fontSize: 15,
        fill: 'noir',
        selectable: false,
      })

      // Ajout de l'objet Textbox au canevas
      this.canvas.add(textBas)
      const textHaut = new Textbox('Haut', {
        left: 300,
        top: 50,
        fontSize: 15,
        fill: 'noir',
        selectable: false,
      })

      // Ajout de l'objet Textbox au canevas
      this.canvas.add(textHaut)
      const textOccultation = new Textbox('Occultation vitrage', {
        left: 250,
        top: 0,
        width: 200,
        fontSize: 15,
        fill: 'noir',
        selectable: false,
      })

      // Ajout de l'objet Textbox au canevas
      this.canvas.add(textOccultation)
      this.createObjet()
    },
  },
}
</script>
