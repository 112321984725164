<template>
  <v-container class="d-flex mt-5 justify-center" v-if="isLoading">
    <custom-progress-circular />
  </v-container>
  <v-container v-else>
    <wizard
      v-if="!isResult"
      squared-tabs
      navigable-tabs
      :show-progress="false"
      :custom-tabs="[
        {
          title: 'Localisation',
        },
        {
          title: 'Orientation',
        },
        {
          title: 'Critères',
        },
        {
          title: 'Information pièce',
        },
        {
          title: 'Configuration de la protection',
        },
      ]"
      @change="onChangeCurrentTab"
      @complete:wizard="submitForm"
    >
      <v-container>
        <location-step v-if="currentTabIndex === 0" v-model="location" />
        <orientation-step v-if="currentTabIndex === 1" v-model="orientation" />
        <criteria-step v-if="currentTabIndex === 2" v-model="criteria" />
        <room-step v-if="currentTabIndex === 3" v-model="room" />
        <sunshade-definition-step
          v-if="currentTabIndex === 4"
          v-model="sunshade"
          :location="location"
          :orientation="orientation"
          :critere="criteria"
          :room="room"
        />
      </v-container>

      <template #back>
        <v-btn
          block
          rounded="lg"
          color="secondary"
          prepend-icon="mdi-arrow-left-bold"
          >Précédent</v-btn
        >
      </template>
      <template #next>
        <v-btn
          block
          rounded="lg"
          color="secondary"
          append-icon="mdi-arrow-right-bold"
          >Suivant</v-btn
        >
      </template>
      <template #done>
        <v-btn block rounded="lg" color="accent" append-icon="mdi-check-bold"
          >Terminé</v-btn
        >
      </template>
    </wizard>
    <!-- Result -->
    <calculate-result v-else :result="result" @reset="resetForm" />
  </v-container>
</template>

<script>
import 'form-wizard-vue3/dist/form-wizard-vue3.css'
import Wizard from 'form-wizard-vue3'
import LocationStep from '@/components/steps/LocationStep'
import OrientationStep from '@/components/steps/OrientationStep'
import CriteriaStep from '@/components/steps/CriteriaStep'
import RoomStep from '@/components/steps/RoomStep'
import SunshadeDefinitionStep from '@/components/steps/SunshadeDefinitionStep'
import calculateService from '@/plugins/services/calculateService'
import CalculateResult from '@/components/calculate/CalculateResult'
import CustomProgressCircular from '@/components/CustomProgressCircular'
export default {
  name: 'CalculateForm',
  components: {
    CustomProgressCircular,
    CalculateResult,
    SunshadeDefinitionStep,
    RoomStep,
    CriteriaStep,
    OrientationStep,
    LocationStep,
    Wizard,
  },
  data() {
    return {
      step: 1,
      isLoading: false,
      currentTabIndex: 0,
      location: '1',
      orientation: '1',
      criteria: '1',
      room: '1',
      sunshade: {
        width: 15,
        spacing: 30,
        tilt: 30,
        occultation: 100,
      },
      result: {},
    }
  },
  computed: {
    form() {
      return {
        criteria_code: this.criteria,
        piece_code: this.room,
        orientation_code: this.orientation,
        meteo_code: this.location,
        blade_width: this.sunshade.width,
        blade_space: this.sunshade.spacing,
        tilt: this.sunshade.tilt,
        occultation: this.sunshade.occultation,
        glazing_distance: 50,
      }
    },
    isResult() {
      return Object.keys(this.result).length && !this.isLoading
    },
  },
  methods: {
    submitForm() {
      this.isLoading = true
      calculateService
        .calculateAction(this.form)
        .then((res) => {
          this.result = res.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onChangeCurrentTab(index) {
      this.currentTabIndex = index
    },
    resetForm() {
      this.result = {}
    },
    calculAllParent() {
      console.log('atooooo')
    },
  },
}
</script>

<style scoped lang="sass">

.form-wizard-vue
  &::v-deep()
    .fw-list-wrapper-icon.fw-step-active.fw-squared-tab
      background: #4bc1bd !important
    .fw-step-checked
      border: 2px solid #4bc1bd !important
    .fw-footer
      padding: 0 30px
</style>
