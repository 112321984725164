<template>
  <v-radio-group :model-value="modelValue" @update:modelValue="onChange">
    <v-radio label=" Ventilation naturelle" value="1"></v-radio>
    <v-radio label="Climatisée" value="2"></v-radio>
  </v-radio-group>
</template>

<script>
import radioMixin from '@/mixins/radioMixin'

export default {
  name: 'RoomStep',
  mixins: [radioMixin],
}
</script>

<style scoped></style>
